





















import { computed, defineComponent, Ref } from '@vue/composition-api';
import KBaseInput from './KBaseInput.vue';
import useInputStyles from '@/@core/styles/inputStyles';
import useInputRules from '@/@core/helpers/inputRules';
import useModelValue from '@/@core/helpers/modelValue';

export default defineComponent({
  components: { KBaseInput },
  name: 'KCheckBoxInput',
  props: {
    'item-value': {
      type: String,
      default: 'value'
    },
    'item-text': {
      type: String,
      default: 'text'
    },
  },
  setup(props, { attrs, emit }) {
    const { label, rules, ...filteredAttrs } = attrs;
    const modelValue = useModelValue({ attrs, emit });

    const formattedRules: Ref<any[]> = computed(() =>
      rules ? useInputRules(rules as any[], attrs.value) : []
    );

    return {
      useInputStyles,
      filteredAttrs,
      formattedRules,
      modelValue
    };
  }
});
